#header.special_hero {
  position: relative;
  min-height: 60vh;

  .container {
    padding-bottom: 5rem;
  }

  .giant_logo {
    position: relative;
    z-index: 2;
    color: $brand-sand;
    @include transitions(all);
  }

  .nav {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.5rem;

    @include until($small-mobile) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .title {
      font-size: 2.4rem;
      line-height: 1;
      color: $brand-sand;
      max-width: 21rem;

      @include until($small-mobile) {
        order: 2;
        max-width: 100%;
        text-align: right;
      }

      @include desktop {
        font-size: 4.4rem;
        max-width: 37rem;
      }

      @include widescreen {
        max-width: 37rem;
      }
    }

    .nav-primary {
      @include transitions(all);

      li a,
      li button {
        color: $brand-sand;
        background: none;

        @include hover {
          background: transparentize($primary, 0.9);
        }
      }

      @include until($small-mobile) {
        justify-self: flex-end;
        order: 1;
        margin-bottom: 1.5rem;
      }
    }
  }

  .bkgrd_img {
    @include transitions(opacity);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $brand-black;

    img {
      opacity: 0.6;
      width: 100%;
      height: 100%;
      object-fit: cover
    }
  }

  .banner_text {
    @include transitions(opacity);
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.5em;
    overflow: hidden;
    font-family: $family-secondary-wide;
    font-weight: 700;
    font-size: 0.6em;
    line-height: 2.5;
    text-transform: uppercase;
    color: $brand-sand;
    white-space: nowrap;
    background: $brand-black;

    @include tablet {
      font-size: 0.7em;
    }

    @include desktop {
      font-size: 0.8em;
      line-height: 2;
    }

    img {
      margin: -2px 1em 0;
      width: 10px;
      opacity: 0.5;
      transform: rotate(180deg);

      @include tablet {
        margin: -3px 1.5em 0;
        width: 20px;
        opacity: 0.8;
      }

      @include desktop {
        margin: -3px 3em 0;
        width: 33px;
        opacity: 1;
      }
    }
  }


  // When Nav is open we need to hide everything and move top primary nav to the right spot on the page...

  &.is-active {

    .giant_logo,
    .title,
    .banner_text,
    .bkgrd_img {
      opacity: 0;
    }

    .giant_logo {
      height: 0;
    }
  }
}