.tbr {
  border: 1px dotted red;
}

.tbg {
  border: 1px dotted green;
}

.tba {
  border: 1px dotted aqua;
}

.tbb {
  border: 1px dotted blue;
}

.tbo {
  border: 1px dotted orange;
}