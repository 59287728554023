#footer {
  font-size: 13px;
  color: $brand-sand;

  .logo {
    width: 100%;
    max-width: 145px;
  }

  a {
    display: block;
    margin: 0.5em 0;
    color: inherit;

    @include hover {
      color: $brand-blue;
    }
  }

  .version {
    color: #333;
  }

  .vcard {

    .org {
      @include hide-text();
    }

    .adr {
      margin-bottom: 2em;
    }
  }


  .socialNav {
    padding-top: 0;
    padding-bottom: 4em;
  }

  .newsletter_signup {

    img {
      margin-bottom: 0.25em;
    }

    span {
      display: block;
      cursor: pointer;
    }
  }
}