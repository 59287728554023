.has-bottom-border {
  border-bottom: 1px solid $brand-black !important;
}

.has-bottom-border-except-last:not(:last-child){
  border-bottom: 1px solid $brand-black !important;
}

.has-top-border {
  border-top: 1px solid $brand-black !important;
}

.has-top-border-except-first:not(:first-child) {
  border-top: 1px solid $brand-black !important;
}

.has-left-border {
  border-top: 1px solid $brand-black !important;
}

.has-right-border {
  border-top: 1px solid $brand-black !important;
}

.has-outline {
  border: 1px solid $brand-black !important;
}
