.calendar_nav {
  
  .level {
    flex-wrap: wrap;
    justify-content: space-around;
    
    @include tablet {
      > div {
        padding: 5px 0;
      }
    }
    
    @include widescreen {
      justify-content: space-between;
    }
  }
  

  
  // Type Nav
  
  .cal_types {
    font-family: $family-secondary-wide;
    font-size: $size-5;
    line-height: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    
    @include tablet {
      font-size: $size-4;
    }
    
    @include desktop {
      font-size: $size-3A;
    }
    
    a {
      display: inline-block;
      padding: 0.4em 0 0.2em;
      transition: color $animation-default;
      
      &.is-active {
        color: $brand-black;
        font-weight: 700;
      }
    }
    
    li:not(:first-child, :last-child) {
      padding: 0 0.8em;
      margin: 0 0.8em;
      border-left: 1px solid #CFE1E0;
      border-right: 1px solid #CFE1E0;
      
      @include tablet {
        padding: 0 1em;
        margin: 0 1em;
      }
    }
  }
  
  
  // Filters
  
  .label {
    display: inline-block;
    margin: 0 10px;
    font-family: $family-primary;
    font-size: $size-6;
    line-height: 0.8em;
    font-weight: normal;
  }
  
  .cal_filters {
    
    select {
      border: none;
      font-weight: bold;
    }
    
  }
  
  .cal_views {
    
    .buttons {
      margin-bottom: 0;
    }
    
    .button {
      margin: 0;
      padding: 15px;
      border-radius: 100em;
      
      .icon {
        width: 22px;
        height: 22px;
      }
      
      &:not(.is-selected){
        color: $brand-blue;
        background: lighten($brand-blue, 10%);
        
      }
    }
  }
}