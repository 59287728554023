
// ==========================================================================
// Formie Variables
// ==========================================================================

// The Pantry Overrides
// Full List of variables are here (as of writting): https://github.com/verbb/formie/blob/craft-3/src/web/assets/frontend/src/scss/_variables.scss

$fui-primay-color-hover: lighten($brand-navy, 20%);

.fui-i {

  --fui-font: objektiv-mk1, sans-serif;

  // Setup colours
  --fui-color: #{$brand-black};
  --fui-primary-color: #{$brand-navy};
  --fui-primary-color-hover: #{$fui-primay-color-hover};
  --fui-error: #{$brand-tomato};

  // Borders
  --fui-border-color: #CBD5E1;
  --fui-border-radius: 4;

  // Focus states
  --fui-focus-border-color: #94A3B8;
  --fui-focus-shadow: 0 0 0 3px #{$brand-blue};

  // Alerts
  --fui-alert-error-bg-color: #{$brand-pink};
  --fui-alert-success-bg-color: #{$brand-salt};
  --fui-alert-success-color: #{$brand-olive};

  // Buttons
  --fui-btn-font-size: 1rem;
  --fui-submit-btn-font-size: 1rem;
  // --fui-submit-btn-bg-color: #{$brand-navy};
  // --fui-submit-btn-bg-color-hover: #{$brand-blue};


  .fui-legend,
  .fui-heading {
    display: block;
    padding: $size-3 0 $size-6;
    font-family: $family-secondary-wide;
    font-size: 1rem;
    line-height: 0.8em;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.fui-btn.fui-submit {
  background-color: $brand-salt;
  color: $brand-black;
  border: none;
}

.fui-btn.fui-submit:hover {
  background-color: $brand-black;
  color: $brand-salt;
}
