.class-details {
  
  .img_gallery {
    order: 1;
    
    @include tablet {
      order: 2;
    }
  }
  
  .primary_content {
    order: 2;
    
    @include tablet {
      order: 1;
    }
  }
}