.waterfall {
  
  &:nth-child(even){
    
    .txt {
      order: 2;
    }
    
    .img {
      order: 1;
    }
  }
}