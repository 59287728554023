.b-notices.is-bottom {
  .notification.is-danger.is-bottom-right {
    padding: 0.75rem 1rem;

    .countdown-timer {
      a {
        text-decoration: none;
      }

      .icon {
        position: relative;
        top: 3px;
        width: 22px;
      }
    }
  }
}

