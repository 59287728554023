#main_nav {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 9;
  top: 0;
  width: 100%;
  background: $text;
  opacity: 0;
  transform: translateY(-100%);

  &.is-active {
    opacity: 1;
    transform: translateY(0%);
    @include transitions(all);
  }

  .logo {
    color: $body-background-color;
  }

  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  a, span {
    padding: 0.1em 0;
    color: $brand-sand;

    @include hover {
      color: $brand-blue;
    }
  }

  .primaryNav {

    .nav-link {
      display: inline-block;
      font-family: $family-secondary-wide;
      font-weight: 600;
      font-size: 3.267rem;
    }
  }

  .secondaryNav {

    .nav-link {
      display: inline-block;
      font-size: $size-5;
      margin: 0.25em 0;
    }
  }

  .newsletter_signup {

    img {
      margin-bottom: 0.25em;
    }

    span {
      display: block;
      cursor: pointer;
    }
  }
}