.is-order-1 {
  order: 1;
}

.is-order-2 {
  order: 2;
}

.is-order-3 {
  order: 3;
}