/* Colors */

	$brand-sand: #EEE5D8;
	$brand-black: #26292C;
	$brand-blue: #ACCAC8;
	$brand-navy: #253857;
	$brand-olive: #596B26;
	$brand-mustard: #FFBF5B;
	$brand-tomato: #C21613;
	$brand-pink: #ECC1B6;
	$brand-salt: #F0F0F0;

	$custom-colors: (
		"sand": ($brand-sand, $brand-navy),
		"black": ($brand-black, $brand-salt),
		"blue": ($brand-blue, $brand-sand),
		"navy": ($brand-navy, $brand-sand),
		"olive": ($brand-olive, $brand-mustard),
		"mustard": ($brand-mustard, $brand-olive),
		"tomato": ($brand-tomato, $brand-pink),
		"pink": ($brand-pink, $brand-tomato),
		"salt": ($brand-salt, $brand-black)
	);

	// Overwritting Bulma's general colors
	$primary: $brand-blue;
	$link: darken($brand-blue, 40%);
	$info: $brand-pink;
	$success: $brand-olive;
	$warning: $brand-mustard;
	$danger: $brand-tomato;
	$dark: lighten($brand-black, 20%);
	$text: $brand-black;

	$button-border-color: $brand-black;

	@mixin bg-dark() {
		&.has-background-black,
		&.has-background-navy,
		&.has-background-olive,
		&.has-background-tomato {
			@content;
		}
	}


/* Baseline Grid & Basic Page Styling */

	$gap: 30px;
	$body-background-color: $brand-sand;

	$fullhd: 1440px - (2 * $gap);
	$small-mobile: 520px;

	$footer-background-color: $brand-black;
	$footer-color: true;


/* Font Styles */

	$family-sans-serif: objektiv-mk1, sans-serif;
	$family-primary: $family-sans-serif;
	$family-secondary: roc-grotesk-condensed, sans-serif;
	$family-secondary-wide: roc-grotesk-wide, sans-serif;

	.is-family-secondary-wide {
		font-family: $family-secondary-wide !important;
	}

	// Making font sizes better match design (15px) & be responsive
	$body-size: 15px;

	$size-1: 4.133rem;		// 62px
	$size-2: 3.867rem;		// 58px
	$size-3: 2rem;				// 30px
	$size-3A: 1.8rem;		// 27px
	$size-4: 1.333rem;		// 20px
	$size-4A: 1.267rem;	// 19px
	$size-4B: 1.2rem;	// 18px
	$size-5: 1.133rem;		// 17px
	$size-6: 1rem;				// 15px (body)
	$size-7: 0.867rem;		// 13px
	$size-8: 0.8rem;			// 12px

	// Extra size classes (those commented out are for reference)
	$size-smallest: $size-8;
	$size-small: $size-7;
	$size-normal: $size-6;
	$size-medium: $size-3;
	$size-large: $size-2;
	$size-larger: $size-1;
	$size-largest: 5.667rem;	// 85px
	$size-huge: 8.133rem; 		// 122px

	.is-size-smallest { font-size: $size-smallest; }
	.is-size-small { font-size: $size-small; }
	.is-size-larger { font-size: $size-larger; }
	.is-size-largest { font-size: $size-largest; }
	.is-size-huge { font-size: $size-huge; }


/* Animations */

	$transitionDuration: 0.2s;
	$animation-timing: 200ms;
	$animation-easing: ease-in-out;
	$animation-default: $animation-timing $animation-easing;


/* Cards */

	$card-shadow: none;
	$card-header-shadow: none;
	$card-radius: 2rem;
	$card-footer-border-top: 1px solid $brand-black;


/* Misc */

	$radius-rounded: 1rem;
	$notification-radius: 0;
	$tag-radius: 0.5rem;


/* Modals */

	$modal-content-width: 400px;
	$modal-card-head-padding: 30px;
	$modal-card-body-padding: 10px;
	$modal-card-head-background-color: white;
	$modal-card-head-border-bottom: none;
	$modal-card-foot-border-top: none;







