/* Button Styles */

.button {
  border-radius: 0;
  
  &.has-no-border {
    border: none;
  }
  
  &.is-lined {
    color: $brand-black;
    font-weight: bold;
    line-height: 1;
    padding: 0 0.3em 0.3em;
    border: solid $brand-black;
    border-width: 1px 0 0 0;
    border-radius: 0 !important;
    background: transparent;
    
    @include hover{
      color: $link;
    }
    
    &.has-text-sand {
      border-color: $brand-sand;
    }
    
    &.has-text-salt {
      border-color: $brand-salt;
    }
    
    &.is-left-tablet {
      
      @include tablet {
        border-width: 0 0 0 1px;
      }
    }
    
    &.is-left-desktop {
      
      @include desktop {
        border-width: 0 0 0 1px;
      }
    }
  }
  
  &.is-fullwidth-mobile {
    
    @include mobile {
      width: 100%;
    }
  }
  
  &.is-icon {
    padding-left: calc(0.5em - 1px);
    padding-right: calc(0.5em - 1px);
    
    .icon {
      margin: 0 !important;
      color:  inherit;
      transition: color $animation-default;
    }
    
    .txt {
      @include hide-text();
    }
    
    @include hover {     
      .icon { 
        color:  $link;
      }
    }
  }
  
  &.is-rounded {
    border-radius: 100em;
  }
  
  
  // animating arrow colors on hover
  .arrow {
    transition: border-color $animation-default;
  }
  
  &:not(:disabled){
    @include hover {
      
      .arrow {
        border-color: $link;
      }
    }
  }
}