.hero {

  // Normal Hero

  .container {
    width: 100%;
  }

  .hero_content {

    .subtitle {

    }

    .title {
      font-size: 3rem;
      line-height: 1;

      @include desktop {
        font-size: 4rem;
      }
    }

    p {

      @include desktop {
        margin-left: 4rem;
      }
    }

    .simple-hero-intro {
      margin-left: 0;
    }
  }

  .bkgrd_img img {
    width: 100%;
    max-width: 333px;
  }


  // Fancy Hero

  .fancy_hero {
    position: relative;

    .hero_content {
      position: relative;
      z-index: 1;

      .title,
      p {

        @include desktop {
          margin-left: 6rem;
        }
      }
    }

    .bkgrd_img {
      position: absolute;
      z-index: 0;
      right: 0;
    }
  }

  // Colors & Typography updates for darker backgrounds

  @include bg-dark{
    color: $brand-sand;

    .subtitle,
    .giant_title {
      color: $brand-mustard;
    }

    .title {
      color: $brand-sand;
    }
  }
}