#header {
  z-index: 10;
  pointer-events: none;
  
  .navbar {
    display: flex;
    padding: 1.5rem;
    justify-content: space-between;
    background: none;
    pointer-events: none;
    
    @include desktop {
      padding: 1.5rem 0;
    }
  }
  
  .navbar-item {
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .logo {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    width: 270px;
    pointer-events: auto;
    opacity: 1;
    @include transitions(opacity);
    
    @include until($small-mobile) {
      max-width: 60%;
    }
    
    svg {
      width: 100%;
    }
    
    @include hover {
      color: lighten($brand-black, 30%);
      background: none;
    }
  }
  
  .nav-primary {
    display: flex;
    pointer-events: auto;
    
    li {
      margin-left: 5px;
      
      @include from($small-mobile) {
        margin-left: 20px;
      }
    }
    
    li a,
    li button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: $brand-sand;
      
      @include hover {
        color: darken($primary, 30%);
        background: transparentize($brand-sand, 0.2);
      }
      
      svg {
        width: 22px;
      }
    }
    
    li a {
      
      span {
        @include hide-text();
      }
    }
    
    .navbar-burger {
      
      span {
        height: 1.5px;
      }
    }
    
    // Cart Button (with counter)
    
    .cart {
      position: relative;
      
      .cart_count {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        min-width: 1.5em;
        line-height: 1.5em;
        border-radius: 1em 1em 1em 1em;
        text-align: center;
        transform: translate( -50%, -60%);
        font-weight: bold;
        background: $brand-mustard;
      }
    }
  }
  
  
  // When Active we change a few things...
  
  &.is-active {
    position: sticky;
    background: transparent !important;
    
    .logo {
      opacity: 0;
      pointer-events: none;
    }
    
    .nav-primary {
    
      li a,
      li button {
        color: $brand-sand;
        background: none;
        
        @include hover {
          background: transparentize($primary, 0.9);
        }
      }
    }
  }
  
  // When it's a dark color
  
  @include bg-dark{
    
    .logo {
      color: $brand-sand;
      
      @include hover {
        color: darken($brand-sand, 30%);
        background: none;
      }
    }
  }
}