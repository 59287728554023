.socialNav {
  display: flex;
  justify-content: flex-start;
  padding: 2em 0;
  
  .nav-item {
    margin-right: 13px;
    
    .nav-link {
      @include hide-text;
      display: block;
      width: 25px;
      height: 25px;
      background-size: 100% 100%;
      
      @include hover{
        opacity: 0.8;
      }
    }
  }
  
  .facebook .nav-link {
    background: url('/assets/images/icons/facebook-sand.svg');
  }
  
  .instagram .nav-link {
    background: url('/assets/images/icons/instagram-sand.svg');
  }
  
  &.is-dark {
    
    .facebook .nav-link {
      background: url('/assets/images/icons/facebook-dark.svg');
    }
    
    .instagram .nav-link {
      background: url('/assets/images/icons/instagram-dark.svg');
    }
  }
  
  &.is-larger {
    .nav-item {
      
      .nav-link {
        width: 32px;
        height: 32px;
      }
    }
  }
}