.card {
  display: block;
  width: 100%;

  .tag {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
  }

  .card-footer-item {
    color: $brand-black;

    @include hover {
      color: $brand-salt;
      background-color: $brand-black;
    }
  }

  .bottom-left-radius {
    border-bottom-left-radius: 2rem;
  }

  .bottom-right-radius {
    border-bottom-right-radius: 2rem;
  }

  &.sold-out {
    img {
      opacity: 0.5;
    }
  }
}

.cal_grid_item .card {
  
  @include hover() {
    color: white;
    background: $brand-black;
    box-shadow: 0 0 0 3px $brand-black;
  }
}