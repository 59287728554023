
/* Headlines & Titles */

.is-brand-smaller-subtitle {
  font-family: $family-secondary-wide;
  font-size: $size-6;
  line-height: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
}

.is-brand-subtitle {
  font-family: $family-secondary-wide;
  font-size: $size-5; // 17px
  line-height: 0.8em;
  font-weight: 700;
  text-transform: uppercase;

  @include tablet {
    font-size: $size-4A; // 19px
  }

  @include desktop {
    font-size: $size-4; // 20px
  }
}

.is-brand-title {
  font-family: $family-secondary;
  font-size: 1.6rem; // 25px
  line-height: 0.8em;
  font-weight: bold;
  text-transform: uppercase;

  @include tablet {
    font-size: 1.733rem; // 26px
  }

  @include desktop {
    font-size: $size-3A; // 27px
  }
}

.is-brand-smaller-headline {
  font-family: $family-secondary;
  font-size: 1.6rem; // 24px
  line-height: 0.824em;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;

  @include tablet {
    font-size: $size-3A; // 27px
  }

  @include desktop {
    font-size: $size-3; // 30px
  }
}

.is-brand-smallish-headline {
  font-family: $family-secondary;
  font-size: $size-3A; // 27px
  line-height: 0.824em;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;

  @include tablet {
    font-size: $size-3; // 30px
  }

  @include desktop {
    font-size: 2.333rem; // 35px
  }
}

.is-brand-headline {
  font-family: $family-secondary;
  font-size: 3.2rem; // 48px
  line-height: 0.824em;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;

  @include tablet {
    font-size: 3.533rem; // 53px
  }

  @include desktop {
    font-size: $size-2; // 58px
  }
}

.is-brand-larger-headline {
  font-family: $family-secondary;
  font-size: 3.2rem; // 48px
  line-height: 0.824em;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;

  @include tablet {
    font-size: 3.667rem; // 55px
  }

  @include desktop {
    font-size: $size-1; // 62px
  }
}

.is-brand-largest-headline {
  font-family: $family-secondary;
  font-size: $size-1;
  line-height: 0.824em;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;

  @include tablet {
    font-size: 4.8rem; // 72px
  }

  @include desktop {
    font-size: $size-largest; // 85px
  }
}

.is-brand-huge-headline {
  font-family: $family-secondary-wide;
  font-size: $size-2;
  line-height: 0.82em;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;

  @include tablet {
    font-size: $size-largest;
  }

  @include desktop {
    font-size: $size-huge;
  }
}

.is-brand-large-body {
  font-size: $size-5;

  @include desktop {
    font-size: $size-4A;
  }
}


/* Links */

.is-reversed-link {
  color: $brand-black;

  @include hover{
    color: $link;
  }
}


/* Line-Height Overrides */

.has-normal-line-height {
  line-height: 1em;
}

.has-short-line-height {
  line-height: 0.824em;
}


/* Columns for Content */

.has-columns-2 {
  column-count: 2;
  column-gap: 4rem;
}