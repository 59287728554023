.arrow {
  content: " ";
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  border: 3px solid $brand-black;
  border-right: 0;
  border-top: 0;
  pointer-events: none;
  transform-origin: center;
  transition: border-color $animation-default; 

  @include hover {
    border-color: $brand-blue;
  }
  
  &.left { 
    transform: rotate(45deg); 
    margin-left: 3px;
  }
  
  &.right { 
    transform: rotate(-135deg); 
    margin-right: 3px;
  }  
}