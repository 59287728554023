// For our Oruga components we hav to make our own build file because it has some weird interactions with Bulma that overrides de

@import 'node_modules/@mdi/font/scss/materialdesignicons.scss';
@import 'the_pantry';
@import 'node_modules/bulma/bulma.sass';
@import 'node_modules/@oruga-ui/theme-bulma/dist/scss/bulma-build.scss';
@import 'helpers';

@import 'base/_arrows.scss';
@import 'base/_borders.scss';
@import 'base/_buttons.scss';
@import 'base/_flexbox.scss';
@import 'base/_formie.scss';
@import 'base/_forms.scss';
@import 'base/_layering.scss';
@import 'base/_modals.scss';
@import 'base/_tag.scss';
@import 'base/_testing.scss';
@import 'base/_typography.scss';
@import 'components/_calendar_nav.scss';
@import 'components/_cards.scss';
@import 'components/_carousel.scss';
@import 'components/_cart_countdown_timer.scss';
@import 'components/_cta_box.scss';
@import 'components/_faqs.scss';
@import 'components/_waterfalls.scss';
@import 'pages/_class.scss';
@import 'pages/_homepg.scss';
@import 'wrapper/_footer.scss';
@import 'wrapper/_header.scss';
@import 'wrapper/_hero-special.scss';
@import 'wrapper/_heros.scss';
@import 'wrapper/_nav-main.scss';
@import 'wrapper/_nav-social.scss';

html, 
body {
  height: 100%;
}

html {
  font-size: 14px;
  
  @include desktop {
    font-size: $body-size;
  }
}

body.menu_open {
  overflow: hidden;
}

#app {
 min-height: 100%;
 display: flex;
 flex-direction: column;
 align-items: stretch;
}

#main {
  flex: 1 0 auto;
}

#header, #footer {
  flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}