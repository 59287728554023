/* Transitions
/ Usage: @include transitions( ( height, padding, top ), .25s, ease );
/--------------------------------- */

@mixin transitions($properties) {
  $transition: ();

  @each $property in $properties {
    $transition: append(
        $transition, ($property $animation-timing $animation-easing), $separator: comma
    );
  }

  transition: $transition;
}


/* ----------------------------------
Hover & Focus

We don't want to declare hover states
because they can make touch devices
freak out. So we'll use a media query
to keep those to devices that have
pointers. But this leads to annoyingly
writing out duplicate styles for focus.

Thus this mixin.
---------------------------------- */

@mixin hover($transitionProps: null) {

  @if $transitionProps {
    @include transitions( ($transitionProps) );
  } @else {
    transition: all $animation-default;
  }

  &:focus {
    @content;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

/* ----------------------------------
Hide Text

Lets text be read by screen readers,
but not shown on screen.
---------------------------------- */

@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.reverse-column-mobile {
  @include until($tablet)  {
    display: flex;
    flex-direction: column-reverse;
  }
}