
.carousel .carousel-indicator .indicator-item .indicator-style.is-active {
  background-color: $brand-black;
  border-color: $brand-black;
}

.carousel .carousel-indicator .indicator-item .indicator-style {
  border-color: $brand-black;
}

.carousel-arrow.icon {
  color: $brand-black;
}