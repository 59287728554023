.faq {
  &:last-child {
    border-bottom: 1px solid $brand-black;
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    border-top: 1px solid $brand-black;
    font-size: $size-6; // 15px
    line-height: 1;
    font-weight: bold;
    color: $brand-black;

    p.q {
      margin-right: 2rem;
    }

    @include tablet {
      font-size: $size-5; // 19px
    }

    @include desktop {
      font-size: $size-4B; // 18px
    }

    @include hover {
      color: $link;
    }
  }

  .content {
    padding-bottom: 1.5rem;
  }
}