/* Layering */

.has-layers {
  position: relative;
  z-index: 0;
}

.is-on-top {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.is-on-bottom {
  position: relative;
  z-index: 0;
}


