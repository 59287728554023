.tag:not(body) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 100em;
  height: 50px;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;

  svg {
    width: 100%;
  }
}