.modal-card {
  .modal-card-head,
  .modal-card-body {
    text-align: center;
    border-bottom: 1px transparent solid;
  }

  .modal-card-foot {
    justify-content: center;
    border: 1px transparent solid;
  }
}

.modal-card.newsletter-modal {
  width: 100%;
}